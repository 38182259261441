import React, { createContext, useContext, useEffect, useState } from "react";
import {
  nameMooda,
  publicRequest,
  slugMooda,
  urlSiteMooda,
} from "../../requestMethods";

const initialCompanyContext = {
  minProduct: 15,
  imgLogo: "defaultLogo.svg",
  companyName: nameMooda,
  companyId: 0,
  slug: slugMooda,
  showDashboard: true,
  enabledPayment: false,
};

export const CompanyContext = createContext();

export const CompanyContextProvider = (props) => {
  const [data, setData] = useState({
    configRules: {
      minProducts: 0,
    },
    configUI: {
      imgLogo: [],
    },
    name: nameMooda,
    companyId: 0,
    slug: slugMooda,
    planContract: {
      showPrice: false,
      showDashboard: true,
    },
    enabledPayment: false,
  });
  const [contextData, setContextData] = useState(initialCompanyContext);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const [_ignoreFirstSlash, rootParam] = window.location.pathname.split("/");

    getCompany(rootParam.toLowerCase());
  }, []);

  const [logo, setLogo] = useState();
  const [minProducts, setMinProducts] = useState();
  const [name, setName] = useState();
  const [showDashboard, setShowDashboard] = useState();
  const {
    configRules,
    configUI,
    name: remoteName,
    slug,
    companyId,
    planContract,
    enabledPayment,
  } = data;

  useEffect(() => {
    setContextData({
      minProduct: minProducts ?? initialCompanyContext.minProduct,
      imgLogo: logo ?? initialCompanyContext.imgLogo,
      companyName: name ?? initialCompanyContext.companyName,
      companyId: companyId ?? initialCompanyContext.companyId,
      slug: slug ?? initialCompanyContext.slug,
      enabledPayment: enabledPayment ?? initialCompanyContext.enabledPayment,
      showDashboard:
        planContract && planContract.showDashboard
          ? planContract.showDashboard
          : initialCompanyContext.showDashboard,
    });
  }, [logo, minProducts, name, companyId, slug, showDashboard, enabledPayment]);

  useEffect(() => {
    if (configUI && configUI.imgLogo) {
      configUI.imgLogo.map((item) =>
        setLogo(item.newName ? item.newName : item)
      );
    }
    if (configRules && configRules.minProducts) {
      setMinProducts(configRules.minProducts);
    }
    if (planContract && planContract.showDashboard) {
      setShowDashboard(planContract.showDashboard);
    }
    setName(remoteName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getCompany = async (currentCompany) => {
    try {
      const res = await publicRequest.get(`/company/${currentCompany}`);

      if (Object.keys(res.data).length > 0) {
        setData(res.data);
      } else {
      }
    } catch (error) {}
  };

  return (
    <CompanyContext.Provider value={contextData}>
      {props.children}
    </CompanyContext.Provider>
  );
};

export const WithCompanyContext = (Component) => {
  const MixinComponent = (props) => {
    return (
      <CompanyContextProvider>
        <Component {...props} />
      </CompanyContextProvider>
    );
  };

  return MixinComponent;
};

export function useCompany() {
  const context = useContext(CompanyContext);
  return context;
}
