import "../../global.css";
import { useEffect, useState } from "react";
import {
  publicRequest,
  userRequest,
  urlMooda,
  whatsAppCall,
} from "../../requestMethods";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/loading";
import { useCompany } from "../../components/context/CompanyContext";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Container from "@mui/material/Container";
import Switch from "@mui/material/Switch";
export default function User() {
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [data, setData] = useState({});
  const [inputs, setInputs] = useState({});
  const [states, setStates] = useState([]);
  const [users, setUsers] = useState([]);
  const [state, setState] = useState();
  const [managedBy, setManagedBy] = useState();
  const [managed, setManaged] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIni, setActiveIni] = useState(false);
  const [active, setActive] = useState("Pendente");
  const { companyId, companyName, slug } = useCompany();
  const currentUser = useSelector((state) => state.user.currentUser);
  const nav = useNavigate();
  const [address, setAddress] = useState();
  const [rzSocial, setRzSocial] = useState();
  const [mainActivity, setMainActivity] = useState();
  const [registerState, setRegisterState] = useState();
  const [cnpj_cpf, setCnpj_cpf] = useState();
  const [contact, setContact] = useState();
  const [rejections, setRejections] = useState([]);
  const [reason, setReason] = useState();
  const [numAddress, setNumAddress] = useState();
  const [complement, setComplement] = useState();
  const [estrangeiro, setEstrangeiro] = useState();
  const [premium, setPremium] = useState(false);

  const _findManaged = (value) => {
    let nameManager = users.find(
      (usr) => usr.userId.toString() === value.toString()
    );

    return nameManager;
  };
  useEffect(() => {
    if (data) {
      if (data.address) {
        setAddress(JSON.parse(data.address));
        setState(data.address.state);
      }
      if (data.managedBy) {
        setManagedBy(data.managedById);
        setManaged({
          contact: data.contactManagedBy,
          name: data.managedBy,
          userId: data.managedById,
        });
      }
      if (data.enabled) {
        setActive(data.enabled);
        setActiveIni(true);
      }
      if (data.rzSocial) {
        setRzSocial(data.rzSocial);
      }
      if (data.mainActivity) {
        setMainActivity(data.mainActivity);
      }
      if (data.registerState) {
        setRegisterState(data.registerState);
      }
      if (data.contact) {
        setContact(data.contact);
      }
      if (data.customerRejectionId) {
        setReason(data.customerRejectionId);
      }
      if (data.foreigner) {
        setEstrangeiro(true);
      }
      if (data.presales) {
        setPremium(data.presales);
      } else {
        setPremium(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (companyId > 0) {
      getStates();
      getManager();
      getReason();
    }
  }, [companyId]);

  useEffect(() => {
    if (id !== "new" && companyId > 0) {
      getCustomers();
    }
  }, [id, companyId]);

  const getCustomers = async () => {
    try {
      const res = await userRequest.get(`${companyId}/customers/find/${id}`);
      res.data.map((item) => {
        setData(item);
      });
    } catch {
      console.error();
    }
  };

  const getStates = async () => {
    const res = await userRequest.get(`${companyId}/customers/state`);

    setStates(res.data);
  };
  const getManager = async () => {
    const res = await userRequest.get(`${companyId}/users`);

    setUsers(
      res.data.filter(
        (item) =>
          item.position === "Vendedor(a)" &&
          (item.deleted === null || item.deleted === undefined)
      )
    );
  };
  const getReason = async () => {
    const res = await userRequest.get(`${companyId}/customerRejections`);

    setRejections(res.data);
  };
  const validadeVen = () => {
    if (
      currentUser.isAdmin &&
      active &&
      (managedBy === "" || managedBy === undefined)
    ) {
      Swal.fire("Atenção!", "Vendedor(a) não selecionado.", "warning");
      return false;
    }

    return true;
  };
  const validate = () => {
    if (inputs.name === "" || inputs.name === undefined) {
      Swal.fire("Atenção!", "O campo Nome deve ser preenchido.", "warning");
      return false;
    }
    // if (inputs.password === "" || inputs.password === undefined) {
    //   Swal.fire("Atenção!", "O campo Senha deve ser preenchido.", "warning");
    //   return false;
    // }
    if (
      (inputs.owner_cpf === "" || inputs.owner_cpf === undefined) &&
      (cnpj_cpf === "" || cnpj_cpf === undefined)
    ) {
      Swal.fire(
        "Atenção!",
        "O campo CPF do proprietário ou CNPJ são obrigátorios.",
        "warning"
      );
      return false;
    }

    if (
      (inputs.owner_cpf !== "" || inputs.owner_cpf !== undefined) &&
      (cnpj_cpf === "" || cnpj_cpf === undefined) &&
      (inputs.onlineStore === "" || inputs.onlineStore === undefined)
    ) {
      Swal.fire(
        "Atenção!",
        "O campo Instagram ou Loja Virtual é obrigátorios.",
        "warning"
      );
      return false;
    }

    /*if (cnpj_cpf === "" || cnpj_cpf === undefined) {
      Swal.fire("Atenção!", "O campo CNPJ deve ser preenchido.", "warning");
      return false;
    }
    if (inputs.owner_cpf === "" || inputs.owner_cpf === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo CPF do Proprietário deve ser preenchido.",
        "warning"
      );
      return false;
    }

    if (!rzSocial || rzSocial === "" || rzSocial === undefined) {
      Swal.fire(
        "Atenção!",
        "O campo Razão Social deve ser preenchido.",
        "warning"
      );
      return false;
    }
    */
    if (contact === "" || contact === undefined) {
      Swal.fire("Atenção!", "O campo Telefone deve ser preenchido.", "warning");
      return false;
    }
    if (inputs.email === "" || inputs.email === undefined) {
      Swal.fire("Atenção!", "O campo Email deve ser preenchido.", "warning");
      return false;
    }
    if (!address || address.street === "" || address.street === undefined) {
      Swal.fire("Atenção!", "O campo Endereço deve ser preenchido.", "warning");
      return false;
    }
    if (!address || address.cep === "" || address.cep === undefined) {
      Swal.fire("Atenção!", "O campo CEP deve ser preenchido.", "warning");
      return false;
    }
    if (!address || address.state === "" || address.state === undefined) {
      Swal.fire("Atenção!", "O campo Estado deve ser selecionado.", "warning");
      return false;
    }

    return true;
  };
  const updateClick = async (event) => {
    setIsLoading(true);

    if (id !== "new") {
      if (validadeVen()) {
        Update();
      }
    } else {
      if (validate()) {
        if (validadeVen()) {
          Inserir();
        }
      }
    }
    setIsLoading(false);
  };
  const ObjCustomer = () => {
    return {
      name: inputs.name,
      //password: inputs.password,
      cnpj_cpf: cnpj_cpf,
      rzSocial: rzSocial,
      contact: contact,
      email: inputs.email,
      owner_cpf: inputs.owner_cpf,
      managedBy: managedBy ? managedBy : currentUser.userId,
      enabled: active,
      customerRejectionId: reason,
      lastUserUpdate: currentUser.userId,
      address: {
        street: inputs.street ? inputs.street : address.street,
        numAddress: numAddress ? numAddress : address.numAddress,
        complement: complement ? complement : address.complement,
        cep: address.cep,
        state: address.state,
        district: address.district,
        city: address.city,
      },
      mainActivity: mainActivity,
      registerState: registerState,
      foreigner: estrangeiro,
      presales: premium,
    };
  };
  const Update = async (event) => {
    try {
      const res = await userRequest.put(
        `${companyId}/customers/${id}`,
        ObjCustomer()
      );
      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Cliente alterado com sucesso!", "success");
        smsSend();
        nav(`../customers`);
      } else {
        Swal.fire("Atenção!", "Erro ao alterar cliente!", "error");
      }
    } catch (error) {}
  };
  const _findRejection = (customerRejectionId) => {
    if (customerRejectionId) {
      const reject = rejections.find(
        (item) =>
          item.customerRejectionId.toString() === customerRejectionId.toString()
      );

      if (reject !== undefined) {
        return reject;
      } else {
        return null;
      }
    }
    return null;
  };
  const smsSend = async () => {
    let obj = {};
    let objVend = {};
    let rejection = _findRejection(reason);

    if (
      (currentUser.isAdmin && active === "Aprovado") ||
      (active === "Aprovado" && !activeIni && currentUser.isAdmin)
    ) {
      obj = {
        contact: contact,
        message:
          companyName +
          ":PARABÉNS!\u{1F389}\nSeu cadastro já está liberado para acessar nossos preços!\u{1F680}\nClique agora no link, clique em ver preços, digite novamente seu telefone para receber o código de liberação e valide seu acesso: " +
          urlMooda +
          slug +
          "/home" +
          " Nossa vendedora " +
          managed.name +
          " irá atender você.",
        //serviceType: "short",
      };
      objVend = {
        contact: managed.contact,
        message:
          "*CLIENTE APROVADO*\u{1F6CD}\nO cliente " +
          data.name +
          " já está aprovado!\nContate agora mesmo!\n" +
          whatsAppCall +
          contact,

        //serviceType: "short",
      };
      const res = await userRequest.post(`${companyId}/customers/sendSMS`, obj);
      const resV = await userRequest.post(
        `${companyId}/customers/sendSMS`,
        objVend
      );
    }
    if (
      (currentUser.isAdmin && active === "Negado") ||
      (active === "Negado" && !activeIni && currentUser.isAdmin)
    ) {
      obj = {
        contact: contact,
        message:
          companyName +
          ":Infelizmente seu cadastro foi negado pelo seguite motivo:" +
          rejection.rejection +
          ".\u{1F625}",
        //serviceType: "short",
      };
      const res = await userRequest.post(`${companyId}/customers/sendSMS`, obj);
    }

    // if(Object.keys(obj).length > 0){
    //   const res = await userRequest.post(`${companyId}/customers/sendSMS`,obj);
    // }
  };
  const Inserir = async () => {
    try {
      const res = await userRequest.post(
        `${companyId}/customers/registerCustomer`,
        [ObjCustomer()]
      );

      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Cliente inserido com sucesso!", "success");
        smsSend();
        nav(`../customers`);
      } else {
        Swal.fire("Atenção!", "Erro ao inserir cliente!", "error");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleChangeContact = (e) => {
    setContact(e.target.value);
  };
  const handleChangeNumAddress = (e) => {
    setNumAddress(e.target.value);
  };
  const handleChangeComplement = (e) => {
    setComplement(e.target.value);
  };
  const handleChangeRz = (e) => {
    setRzSocial(e.target.value);
  };
  const handleChangeState = (e) => {
    setState(e.target.value);
  };
  const handleChangeManager = (e) => {
    setManagedBy(e.target.value);

    setManaged(_findManaged(e.target.value));
  };

  const handleChangeActive = (e) => {
    setActive(e.target.value);
  };
  const handleChangeReason = (e) => {
    setReason(e.target.value);
  };
  const handleChangeCEP = (e) => {
    if (e.target.value.length === 8) {
      getCep(e.target.value.trim().replace("-", ""));
    }
  };
  const handleChangeCNPJ = (e) => {
    if (e.target.value.length === 14) {
      getCNPJ(e.target.value.trim());
    }
  };

  const getCep = async (cep) => {
    const res = await userRequest.get(`${companyId}/customers/cep/${cep}`);

    setAddress({
      street: res.data.logradouro,
      complement: res.data.complement,
      cep: res.data.cep,
      state: res.data.uf,
      district: res.data.bairro,
      city: res.data.localidade,
    });
  };
  const getCNPJ = async (cnpj) => {
    const res = await userRequest.get(`${companyId}/customers/cnpj/${cnpj}`);

    setRzSocial(res.data.razao_social);
    setMainActivity(res.data.atividadePrincipal);
    setRegisterState(res.data.inscricao_estadual);
    setCnpj_cpf(cnpj);
  };
  const handleChangeEstrangeiro = (e) => {
    //setAdmin(e.target.value);
    setEstrangeiro(e.target.checked);
  };
  const handleChangeEnabled = (e) => {
    setPremium(e.target.checked);
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="div_custom_Form">
          {id !== "new" ? (
            <span className="div_custom_UpdateTitle">Editar Cliente</span>
          ) : (
            <span className="div_custom_UpdateTitle">Novo Cliente</span>
          )}
          <div className="div_custom_UpdateLeft">
            <div className="div_custom_UpdateItem">
              <label>Premium</label>
              <input
                className="star"
                type="checkbox"
                name="enabled"
                checked={premium}
                onChange={handleChangeEnabled}
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Telefone</label>
              <input
                type="text"
                name="contact"
                onChange={handleChangeContact}
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" ? contact || "" : ""}
                maxLength="12"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Nome do Proprietário</label>
              <input
                type="text"
                name="name"
                defaultValue={id !== "new" ? data.name || "" : ""}
                className="div_custom_UpdateInput"
                onChange={handleChange}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>CPF do Proprietário do CNPJ</label>
              <input
                type="text"
                pattern="\d*"
                name="owner_cpf"
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" ? data.owner_cpf || "" : ""}
                onChange={handleChange}
                maxLength="14"
              />
            </div>
            {/* <div className="div_custom_UpdateItem">
              <label>Senha</label>
              <input
                type="text"
                name="password"
                defaultValue={id !== "new" ? "" || "" : ""}
                className="div_custom_UpdateInput"
                onChange={handleChange}
                maxLength="20"
              />
            </div> */}
            <div className="div_custom_UpdateItem">
              <label>CNPJ</label>
              <input
                type="text"
                pattern="\d*"
                name="cnpj_cpf"
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" ? data.cnpj_cpf || "" : ""}
                onChange={handleChangeCNPJ}
                maxLength="14"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Razão Social</label>
              <input
                type="text"
                name="rzSocial"
                className="div_custom_UpdateInput"
                onChange={handleChangeRz}
                defaultValue={rzSocial ? rzSocial : ""}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Atividade Principal</label>
              <input
                type="text"
                name="mainActivity"
                className="div_custom_UpdateInput"
                defaultValue={mainActivity ? mainActivity : ""}
                maxLength="200"
                readOnly={true}
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Inscrição Estadual</label>
              <input
                type="text"
                name="registerState"
                className="div_custom_UpdateInput"
                defaultValue={registerState ? registerState : ""}
                maxLength="200"
                readOnly={true}
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Instagram ou Loja Virtual do seu cliente</label>
              <input
                type="text"
                name="onlineStore"
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" ? data.onlineStore || "" : ""}
                onChange={handleChange}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Email</label>
              <input
                type="email"
                name="email"
                className="div_custom_UpdateInput"
                defaultValue={id !== "new" ? data.email || "" : ""}
                onChange={handleChange}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>CEP</label>
              <input
                type="text"
                pattern="\d*"
                name="cep"
                onChange={handleChangeCEP}
                className="div_custom_UpdateInput"
                defaultValue={address ? address.cep || "" : ""}
                maxLength="8"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Endereço</label>
              <input
                type="text"
                name="street"
                onChange={handleChange}
                className="div_custom_UpdateInput"
                defaultValue={address ? address.street || "" : ""}
                maxLength="200"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Numero</label>
              <input
                type="text"
                name="numAddress"
                onChange={handleChangeNumAddress}
                className="div_custom_UpdateInput"
                defaultValue={address ? address.numAddress || "" : ""}
                maxLength="8"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Complemento</label>
              <input
                type="text"
                name="complement"
                onChange={handleChangeComplement}
                className="div_custom_UpdateInput"
                defaultValue={address ? address.complement || "" : ""}
                maxLength="8"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Estado</label>
              <select
                className="div_custom_Select"
                name="state"
                value={address ? address.state : ""}
              >
                <option value=""></option>
                {states
                  ? states.map((item) => (
                      <option value={item.stateId}>{item.state}</option>
                    ))
                  : false}
              </select>
            </div>
            <div className="div_custom_UpdateItem">
              <label>Cidade</label>
              <input
                type="text"
                name="city"
                onChange={handleChange}
                className="div_custom_UpdateInput"
                defaultValue={address ? address.city : ""}
                maxLength="8"
              />
            </div>
            <div className="div_custom_UpdateItem">
              <label>Vendedor(a)</label>
              <select
                className="div_custom_Select"
                name="managerBy"
                id="managerBy"
                onChange={handleChangeManager}
                value={managedBy ? managedBy : ""}
              >
                <option value=""></option>
                {users
                  ? users.map((item) => (
                      <option value={item.userId}>{item.name}</option>
                    ))
                  : false}
              </select>
            </div>
            {currentUser.isAdmin ? (
              <>
                <div className="div_custom_UpdateItem">
                  <label>Visão dos Preços</label>
                  <select
                    className="div_custom_Select"
                    name="isActive"
                    onChange={handleChangeActive}
                    value={active}
                  >
                    <option value=""></option>
                    <option value="Aprovado">Aprovado</option>
                    <option value="Negado">Negado</option>
                    <option value="Pendente">Pendente</option>
                  </select>
                </div>
                {active && active === "Negado" ? (
                  <div className="div_custom_UpdateItem">
                    <label>Motivo do Negado</label>
                    <select
                      className="div_custom_Select"
                      name="reason"
                      onChange={handleChangeReason}
                      value={reason}
                    >
                      <option value=""></option>
                      {rejections
                        ? rejections.map((item) => (
                            <option value={item.customerRejectionId}>
                              {item.rejection}
                            </option>
                          ))
                        : false}
                    </select>
                  </div>
                ) : (
                  false
                )}
              </>
            ) : (
              false
            )}
            <div className="div_custom_UpdateItem">
              <label>Estrangeiro</label>
              <Switch
                checked={estrangeiro}
                onChange={(e) => handleChangeEstrangeiro(e)}
              />
            </div>
          </div>
          <div>
            {id !== "new" ? (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={updateClick}
              >
                Alterar
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={updateClick}
              >
                Cadastrar
              </Button>
            )}
            <Button
              variant="outlined"
              color="secondary"
              className="custom_add_back"
              onClick={() => nav(-1)}
            >
              Voltar
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
}
